import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login/Login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register/Register')),
    },
    {
        key: 'forgotpassword',
        path: `${AUTH_PREFIX_PATH}/forgot/password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password/ForgotPassword')),
    },
    {
        key: 'resetpassword',
        path: `${AUTH_PREFIX_PATH}/reset/password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password/ResetPassword')),
    },
    {
        key: 'confirmemail',
        path: `${AUTH_PREFIX_PATH}/confirm-email/:token`,
        component: React.lazy(() => import('views/auth-views/authentication/confirm-email/ConfirmEmail')),
    },
    {
        key: 'confirmemailpageinfo',
        path: `${AUTH_PREFIX_PATH}/confirm-email/info`,
        component: React.lazy(() => import('views/auth-views/authentication/confirm-email/ConfirmEmailPageInfo')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default/DefaultDashboard')),
    },
    {
        key: 'applications.applications-list',
        path: `${APP_PREFIX_PATH}/view/all`,
        component: React.lazy(() => import('views/app-views/applications/AllApplications'))
    },
    {
        key: 'applications-all-application',
        path: `${APP_PREFIX_PATH}/all/subscription`,
        component: React.lazy(() => import('views/app-views/applications/VendorDatabase'))
    },
    {
        key: 'applications-add-application',
        path: `${APP_PREFIX_PATH}/add/subscription`,
        component: React.lazy(() => import('views/app-views/applications/AddSubscription'))
    },
    {
        key: 'applications-edit-application',
        path: `${APP_PREFIX_PATH}/edit/subscription`,
        component: React.lazy(() => import('views/app-views/applications/EditSubscription'))
    },
    {
        key: 'statistics',
        path: `${APP_PREFIX_PATH}/statistics`,
        component: React.lazy(() => import('views/app-views/analytics/AnalyticsData '))
    },
    {
        key: 'paymentmethod',
        path: `${APP_PREFIX_PATH}/financials/payment/method`,
        component: React.lazy(() => import('views/app-views/Financials/PaymentMethod'))
    },
    {
        key: 'spend',
        path: `${APP_PREFIX_PATH}/financials/spend`,
        component: React.lazy(() => import('views/app-views/Financials/Spend'))
    },
    {
        key: 'transaction',
        path: `${APP_PREFIX_PATH}/financials/transaction`,
        component: React.lazy(() => import('views/app-views/Financials/Transaction'))
    },
    {
        key: 'invoce',
        path: `${APP_PREFIX_PATH}/financials/invoice`,
        component: React.lazy(() => import('views/app-views/Financials/Invoce'))
    },
    {
        key: 'integrations_page',
        path: `${APP_PREFIX_PATH}/integrations/integrations`,
        component: React.lazy(() => import('views/app-views/integrations/Integrations'))
    },
    {
        key: 'settings_company',
        path: `${APP_PREFIX_PATH}/settings/company`,
        component: React.lazy(() => import('views/app-views/settings/CompanySettings'))
    },
    {
        key: 'settings_user_profile',
        path: `${APP_PREFIX_PATH}/settings`,
        component: React.lazy(() => import('views/app-views/settings/Settings'))
    },
    {
        key: 'infoprofile',
        path: `${APP_PREFIX_PATH}/info/profile`,
        component: React.lazy(() => import('views/app-views/profile/Profile'))
    },
    {
        key: 'editprofile',
        path: `${APP_PREFIX_PATH}/edit/profile`,
        component: React.lazy(() => import('views/app-views/profile/EditProfile'))
    },
    {
        key: 'change-password',
        path: `${APP_PREFIX_PATH}/profile/change/password`,
        component: React.lazy(() => import('views/app-views/profile/ChangePassword'))
    },
    {
        key: 'editnamesubscription',
        path: `${APP_PREFIX_PATH}/edit/name/subscription`,
        component: React.lazy(()=> import('views/app-views/applications/EditNameSubscription'))
    },
    {
        key: "integrationmonobank",
        path: `${APP_PREFIX_PATH}/integration/monobank`,
        component: React.lazy(()=> import('views/app-views/integrations/IntegrationMonobank'))
    },
    {
        key: "integrationgoogleworkspace",
        path: `${APP_PREFIX_PATH}/integration/google/workspace`,
        component: React.lazy(()=> import('views/app-views/integrations/IntegrationGoogleWorkSpace'))
    },
    {
        key: "integrationwise",
        path: `${APP_PREFIX_PATH}/integration/wise`,
        component: React.lazy(()=> import('views/app-views/integrations/IntegrationWise'))
    },
    {
        key: "upcoming",
        path: `${APP_PREFIX_PATH}/applications/upcoming`,
        component: React.lazy(()=> import('views/app-views/applications/Upcoming'))
    },
    {
        key: "notifications",
        path: `${APP_PREFIX_PATH}/notifications`,
        component: React.lazy(()=> import('views/app-views/notifications/Notifications'))
    },
    {
        key: "detailssubscription",
        path: `${APP_PREFIX_PATH}/details/subscription/:id`,
        component: React.lazy(()=> import('views/app-views/applications/DetailsSubscription'))
    },
    {
        key: "alltransaction",
        path: `${APP_PREFIX_PATH}/financials/all/transaction`,
        component: React.lazy(()=> import('views/app-views/Financials/AllTransaction'))
    },
    {
        key: "people",
        path: `${APP_PREFIX_PATH}/organization/people`,
        component: React.lazy(()=> import('views/app-views/organization/People'))
    },
    {
        key: 'authorizationconfirmemail',
        path: `${APP_PREFIX_PATH}/authorization/confirm-email`,
        component: React.lazy(() => import('views/app-views/profile/AuthorizationConfirmEmail')),
    },
    {
        key: 'plansandbilling',
        path: `${APP_PREFIX_PATH}/settings/plans/and/billing`,
        component: React.lazy(() => import('views/app-views/settings/BillingSettings')),
    },
    {
        key: 'quickbook',
        path: `${APP_PREFIX_PATH}/quick/book`,
        component: React.lazy(() => import('views/app-views/integrations/IntegrationQuickBook')),
    },
    {
        key: 'billinghistory',
        path: `${APP_PREFIX_PATH}/billing/history`,
        component: React.lazy(() => import('views/app-views/settings/BillingHistory')),
    }
]